import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/runner/_work/designsystem-docs/designsystem-docs/src/templates/MDXPage.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DSExample = makeShortcode("DSExample");
const UUTestReport = makeShortcode("UUTestReport");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Bruk av accordion`}</h2>
    <p>{`Accordion brukes der du har mye informasjon som du ønsker å gjøre tilgjengelig for brukere, uten at alt er visuelt synlig samtidig. En accordion er bygget opp av en «header» og et «panel», og i SpareBank 1 er hele «headeren» klikkbar. `}</p>
    <h2>{`Accordion`}</h2>
    <p>{`En accordion er bygget opp av to komponenter: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Accordion`}</code>{` og `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`AccordionItem`}</code>{`.
Accordion-komponenten legges rundt alle AccordionItem's, for å gruppere og sette samme overskriftsnivå på alle.`}</p>
    <DSExample name="accordion_Accordion" mdxType="DSExample" />
    <UUTestReport reportID="Accordion_Accordion" mdxType="UUTestReport" />
    <h2>{`AccordionItem`}</h2>
    <p>{`AccordionItem er hver enkel «header»- og «panel»-seksjon. De er lukket by default, men du kan velge at den skal være åpen fra start ved å sende med property `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`defaultOpen`}</code>{`. `}</p>
    <DSExample name="accordion_Accordion_open" mdxType="DSExample" />
    <p>{`Alle `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<AccordionItem />`}</code>{`-komponenter har innebygget funksjonalitet for å styre
åpning og lukking. Men om ønskelig kan du også overstyre dette. Et bruksområde
kan være å lage en komponent som kun kan ha ett element åpent om gangen.`}</p>
    <p>{`Bruker man `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`isOpen`}</code>{`-propen vil intern-logikk som styrer åpning og lukking være skrudd av.`}</p>
    <DSExample name="accordion_Accordion_managed" mdxType="DSExample" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      